import * as React from 'react';
import {
  Show,
  useShowController,
  TextField,
  FileField,
  ImageField,
  ShowProps,
  EditButton,
  DeleteButton,
  TopToolbar,
  ListButton,
  Labeled,
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
// import { makeStyles, createStyles, Theme } from '@mui/styles';
import { ProductTypeTitle } from './ProductTypeTitle';
import { ProductTypeExtended } from '../types';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       maxWidth: 800,
//     },
//   }),
// );

// const HtmlTooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     fontSize: theme.typography.pxToRem(10),
//     border: '1px solid #dadde9',
//   },
// }))(Tooltip);

const CustomDivider = () => (
  <Box mt={2} mb={2}>
    <Divider />
  </Box>
);

const ProductTypeShowActions = () => (
  <TopToolbar>
    <ListButton />
    <EditButton />
    <DeleteButton />
  </TopToolbar>
);

export const ProductTypeShow: React.FC<ShowProps> = (props) => {
  const { record } = useShowController<ProductTypeExtended>();
  return (
    <Show
      title={<ProductTypeTitle />}
      actions={<ProductTypeShowActions />}
      sx={{ maxWidth: 800 }}
      {...props}
    >
      <Card sx={{ maxWidth: 800 }}>
        <CardContent>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={1} mr={{ md: 0, lg: '1em' }}>
              <Box
                display={{ xs: 'block', sm: 'flex' }}
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ sm: 'flex-start' }}
              >
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="Name">
                    <TextField source="id" label="Name" record={record} />
                  </Labeled>
                </Box>
                <Box flex={1}>
                  <Labeled label="Rating">
                    <TextField source="rating" record={record} />
                  </Labeled>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="No of Injectors">
                    <TextField source="noOfInjectors" record={record} />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0 }}>
                  <Labeled label="No of Pumps">
                    <TextField source="noOfPumps" record={record} />
                  </Labeled>
                </Box>
                <Box flex={2}>
                  <Labeled label="No of ECUs">
                    <TextField source="noOfECUs" record={record} />
                  </Labeled>
                </Box>
              </Box>
              <CustomDivider />
              <Typography variant="h6" gutterBottom>
                Firmware
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="Version">
                    <TextField
                      source="firmwarebundleVersion"
                      label="Firmware version"
                      emptyText="-"
                      record={record}
                    />
                  </Labeled>
                </Box>
                <Box flex={1}>
                  <Labeled label="Date">
                    <TextField
                      source="firmwarebundleDate"
                      label="Firmware date"
                      emptyText="-"
                      record={record}
                    />
                  </Labeled>
                </Box>
              </Box>

              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="File">
                    {record?.firmwarebundleFile ? (
                      <FileField
                        source="firmwarebundleFile"
                        title="firmwarebundleFileTitle"
                        download={record.firmwarebundleFileTitle}
                        record={record}
                      />
                    ) : (
                      <TextField emptyText="No firmware uploaded" />
                    )}
                  </Labeled>
                </Box>
              </Box>

              <CustomDivider />
              <Typography variant="h6" gutterBottom>
                Suspect Parameters & Failure Mode Identifiers
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="SPN File">
                    {record?.spnFile ? (
                      <FileField
                        source="spnFile"
                        title="spnFileTitle"
                        record={record}
                        download={
                          record && record.spnFileTitle
                            ? record.spnFileTitle
                            : 'fmi.json'
                        }
                      />
                    ) : (
                      <TextField
                        emptyText="No file uploaded"
                        source="spnFile"
                        record={record}
                      />
                    )}
                  </Labeled>
                </Box>
                <Box flex={1}>
                  <Labeled label="FMI File">
                    {record?.fmiFile ? (
                      <FileField
                        source="fmiFile"
                        title="fmiFileTitle"
                        record={record}
                        download={
                          record && record.fmiFileTitle
                            ? record.fmiFileTitle
                            : 'fmi.json'
                        }
                      />
                    ) : (
                      <TextField emptyText="No file uploaded" />
                    )}
                  </Labeled>
                </Box>
              </Box>
              <CustomDivider />
              <Typography variant="h6" gutterBottom>
                Documentation
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="Documentation title">
                    <TextField
                      source="documentationTitle"
                      emptyText="-"
                      record={record}
                    />
                  </Labeled>
                </Box>
                <Box flex={1}>
                  <Labeled label="Documentation type">
                    <TextField
                      source="documentationType"
                      emptyText="-"
                      record={record}
                    />
                  </Labeled>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                  <Labeled label="Image">
                    {record?.productImage ? (
                      <ImageField
                        source="productImage"
                        data-cy="productImage"
                        record={record}
                      />
                    ) : (
                      <TextField emptyText="No image uploaded" />
                    )}
                  </Labeled>
                </Box>
                <Box flex={1}>
                  <Labeled label="Documentation file">
                    {record?.documentationFile ? (
                      <FileField
                        source="documentationFile"
                        title="documentationFileTitle"
                        download={record.documentationFileTitle}
                        record={record}
                      />
                    ) : (
                      <TextField emptyText="No file uploaded" />
                    )}
                  </Labeled>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Show>
  );
};

export default ProductTypeShow;
