import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  required,
  TopToolbar,
  ShowButton,
  ListButton,
  PasswordInput,
  email,
} from 'react-admin';
import { UserTitle } from './UserTitle';
import { validateForm } from './UserCreate';
import GroupsInput from './GroupsInput';

const UserEditActions = () => (
  <TopToolbar>
    <ListButton />
    <ShowButton />
  </TopToolbar>
);

export const UserEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} title={<UserTitle />} actions={<UserEditActions />}>
      <SimpleForm
        validate={validateForm}
        sx={{
          '& .MuiTextField-root': { width: '256px' },
          '& .MuiInputBase-root': { width: '256px' },
        }}
      >
        <TextInput source="name" />
        <TextInput source="email" />
        <GroupsInput />
        <PasswordInput source="password" />
        <PasswordInput source="confirm_password" />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
