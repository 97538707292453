import GeneratorIcon from '@mui/icons-material/DeveloperBoard';
import ProductList from './ProductList';
import ProductShow from './ProductShow';

const Handlers = {
  list: ProductList,
  show: ProductShow,
  icon: GeneratorIcon,
};

export default Handlers;
