import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  EditActionsProps,
  TopToolbar,
  ListButton,
  PasswordInput,
  email,
} from 'react-admin';
import GroupsInput from './GroupsInput';

export const UserCreateActions: React.FC<EditActionsProps> = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
);

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as Record<string, any>;
  if (!values.name) {
    errors.name = 'ra.validation.required';
  }

  if (!values.email) {
    errors.email = 'ra.validation.required';
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }

  if (!values.password) {
    errors.password = 'ra.validation.required';
  } else if (values.password.length < 8) {
    errors.password = 'resources.users.errors.password_length';
  } else if (
    !values.confirm_password ||
    values.password !== values.confirm_password
  ) {
    errors.confirm_password = 'resources.users.errors.password_mismatch';
  }

  return errors;
};

export const UserCreate: React.FC<CreateProps> = (props) => (
  <Create {...props} actions={<UserCreateActions />}>
    <SimpleForm
      validate={validateForm}
      sx={{
        '& .MuiTextField-root': { width: '256px' },
        '& .MuiInputBase-root': { width: '256px' },
      }}
    >
      <TextInput source="name" />
      <TextInput source="email" />
      <GroupsInput />
      <PasswordInput source="password" />
      <PasswordInput source="confirm_password" />
    </SimpleForm>
  </Create>
);

export default UserCreate;
