import * as React from 'react';
import { Create, CreateProps, TopToolbar, ListButton } from 'react-admin';
import ProductTypeEditDetails from './ProductTypeEditDetails';

export const ProductTypeCreateActions = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
);

export const ProductTypeCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create
      {...props}
      actions={<ProductTypeCreateActions />}
      sx={{ maxWidth: '800px' }}
    >
      <ProductTypeEditDetails create />
    </Create>
  );
};

export default ProductTypeCreate;
