import * as React from 'react';
import { useMutation } from '@apollo/client';
import {
  useGetList,
  useAuthProvider,
  useSafeSetState,
  UserIdentity,
  useTheme,
  useRefresh,
} from 'react-admin';
import { Tooltip, Button, Menu, MenuItem, Box } from '@mui/material';
import AssumeIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import gql from 'graphql-tag';
import { Auth } from 'aws-amplify';
import { getApolloContext } from '@apollo/client';
import { loader } from 'graphql.macro';
import { getTheme } from './getTheme';
import { Tenant } from '../graphql/types';

const ASSUME_TENANT = gql`
  mutation ($tenantId: ID!) {
    assumeTenant(tenantId: $tenantId)
  }
`;

interface State {
  loading: boolean;
  loaded: boolean;
  identity?: UserIdentity;
  error?: any;
}

const TenantSwitcher = () => {
  const [, setTheme] = useTheme();
  const refresh = useRefresh();
  const [assumeTenant] = useMutation(ASSUME_TENANT);
  const [tenantMenu, setTenantMenu] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [loadIdentity, setLoadIdentity] = React.useState(true);
  const { client } = React.useContext(getApolloContext());
  const query = loader('../dataProvider/queries/getTheme.graphql');

  const handleTenantIconClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setTenantMenu(event.currentTarget);
  };

  const handleTenantMenuClose = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setTenantMenu(null);
    event.preventDefault();
  };

  const {
    data: tenants,
    isLoading: tenantsLoading,
    error,
  } = useGetList<Tenant>('tenants', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'meta.timestamp', order: 'DESC' },
  });

  const authProvider = useAuthProvider();
  const [state, setState] = useSafeSetState<State>({
    loading: true,
    loaded: false,
  });

  React.useEffect(() => {
    if (!loadIdentity) return;
    const callAuthProvider = async () => {
      try {
        const identity = await authProvider.getIdentity?.();
        setState({
          loading: false,
          loaded: true,
          identity: identity || undefined,
        });
      } catch (error) {
        setState({
          loading: false,
          loaded: true,
          error,
        });
      }
      refresh();
      setLoadIdentity(false);
    };
    callAuthProvider();
  }, [authProvider, setState, loadIdentity, refresh]);

  if (tenantsLoading || state?.loading) return null;

  const userTenant = tenants?.find((t) => t.id === state?.identity?.tenantId);

  return (
    <>
      <Tooltip title="Change Tenant" enterDelay={300}>
        <Button
          color="inherit"
          data-cy="tenant-switcher-menu"
          aria-owns={tenantMenu ? 'tenant-menu' : undefined}
          aria-haspopup="true"
          onClick={handleTenantIconClick}
          data-ga-event-category="header"
          data-ga-event-action="tenant"
        >
          <AssumeIcon />
          <Box
            component="span"
            sx={{
              margin: (theme) => theme.spacing(0, 0.5, 0, 1),
              display: { xs: 'none', md: 'block' },
            }}
          >
            {userTenant?.name}
          </Box>
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Menu
        id="tenant-menu"
        anchorEl={tenantMenu}
        open={Boolean(tenantMenu)}
        onClose={handleTenantMenuClose}
      >
        {tenants &&
          tenants.map((tenant) => (
            <MenuItem
              data-no-link="true"
              key={tenant.id}
              data-cy="tenant-switcher-menu-item"
              selected={userTenant?.id === tenant.id}
              onClick={async (event) => {
                event.preventDefault();
                await assumeTenant({ variables: { tenantId: tenant.id } });

                try {
                  const cognitoUser = await Auth.currentAuthenticatedUser();
                  const currentSession = await Auth.currentSession();
                  cognitoUser.refreshSession(
                    currentSession.getRefreshToken(),
                    (err: any, session: any) => {
                      setLoadIdentity(true);
                      client
                        ?.query({ query, fetchPolicy: 'network-only' })
                        .then((result) => {
                          const theme = result.data.getTenant.theme || 'light';
                          setTheme(getTheme(theme));
                        });
                    },
                  );
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.log('Unable to refresh Token', e);
                }

                setTenantMenu(null);
              }}
            >
              {tenant.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default TenantSwitcher;
