import * as React from 'react';
import { FC } from 'react';
import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

import { Product } from '../types';

const StatusField = () => {
  const record = useRecordContext<Product>();
  if (!record) {
    return null;
  }

  return (
    <span
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
      }}
    >
      <Chip
        size="small"
        key={record.id}
        sx={{
          margin: 0,
          color: '#fff',
          background: record.status === 'OK' ? green[500] : red[500],
        }}
        label={record.status}
      />
    </span>
  );
};

export default StatusField;
