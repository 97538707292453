import { ApolloClient } from '@apollo/client';
import { FileType } from '../../../graphql/types';
import { getUploadUrl, fileUploader } from './';

export function createUploadPromise(
  client: ApolloClient<object>,
  productType: string,
  type: FileType,
  fieldSetter: (value: string) => void,
  file?: File,
): Promise<void> | undefined {
  return file
    ? getUploadUrl(client, productType, type)
        .then(({ url, fields, baseUrl }) => {
          return fileUploader(url, fields, file, baseUrl);
        })
        .then(({ response, baseUrl }) => {
          if (response.ok) {
            fieldSetter(`${baseUrl}${file.name}`);
          }
        })
    : undefined;
}
