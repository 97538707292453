export function dynamicSort(property: string, order: string) {
  let sortOrder = 1;
  if (order === 'DESC') {
    sortOrder = -1;
  }
  return function (a: any, b: any) {
    let aProp = a[property];
    let bProp = b[property];
    if (!a.hasOwnProperty(property)) {
      aProp = '';
    }
    if (!b.hasOwnProperty(property)) {
      bProp = '';
    }
    const result = aProp < bProp ? -1 : aProp > bProp ? 1 : 0;
    return result * sortOrder;
  };
}
