import * as React from 'react';
import { PropsWithChildren } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { Auth } from 'aws-amplify';

const url = process.env.REACT_APP_API_URL;
const region = process.env.REACT_APP_REGION;

const auth: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => {
    const session = await Auth.currentSession().catch(() => null);
    return session?.getIdToken().getJwtToken() ?? '';
  },
};

const httpLink = createHttpLink({
  uri: url,
});

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const AppProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppProvider;
