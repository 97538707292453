import UsersIcon from '@mui/icons-material/People';
import UserList from './UserList';
import UserShow from './UserShow';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';

const Handlers = {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  icon: UsersIcon,
};

export default Handlers;
