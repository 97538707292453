import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    configuration: 'Configuration',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
  },
  resources: {
    users: {
      name: 'User |||| Users',
      errors: {
        password_mismatch:
          'The password confirmation is not the same as the password.',
        password_length: 'The password must be at least 8 characters long',
      },
    },
    producttypes: {
      name: 'Product Type |||| Product Types',
    },
    products: {
      name: 'Product |||| Products',
      tabs: {
        information: 'Information',
        notifications: 'Notifications',
        statistics: 'Statistics',
        components: 'Components',
        adjustments: 'Adjustments',
      },
      fieldGroups: {
        history: 'History',
      },
    },
    productslist: {
      empty: 'No Products yet.',
    },
  },
};

export default customEnglishMessages;
