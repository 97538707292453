import * as React from 'react';
import { Datagrid, List, ListProps, TextField } from 'react-admin';

import GroupsField from './GroupsField';

const UserList: React.FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="email" />
      <GroupsField />
    </Datagrid>
  </List>
);

export default UserList;
