import {
  TextInput,
  NumberInput,
  required,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  SimpleForm,
} from 'react-admin';
import { Box, Typography, Divider } from '@mui/material';

const VALIDDATE_REGEX = /(20[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/i;

export const validateFirmwareDate =
  (message = 'Must be in the following format: yyyymmdd') =>
  (value?: any) =>
    value === undefined || VALIDDATE_REGEX.test(value) || value === ''
      ? undefined
      : message;

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);

const ProductTypeEditDetails = (props: any) => {
  const { create, ...rest } = props;

  return (
    <SimpleForm>
      <Box display={{ md: 'block', lg: 'flex' }} sx={{ width: '100%' }}>
        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
              <TextInput
                source="id"
                disabled={!!!create}
                validate={[required()]}
                label="Name"
                fullWidth
              />
            </Box>
            <Box flex={1}>
              <TextInput source="rating" validate={[required()]} fullWidth />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
              <NumberInput
                source="noOfInjectors"
                validate={[required()]}
                label="No of Injectors"
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
              <NumberInput
                source="noOfPumps"
                validate={[required()]}
                label="No of Pumps"
                fullWidth
              />
            </Box>
            <Box flex={2}>
              <NumberInput
                source="noOfECUs"
                label="No of ECUs"
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>
          <CustomDivider />
          <Typography variant="h6" gutterBottom>
            Firmware
          </Typography>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
              <TextInput
                source="firmwarebundleVersion"
                label="Version"
                fullWidth
              />
            </Box>
            <Box flex={1}>
              <TextInput
                source="firmwarebundleDate"
                label="Date"
                validate={[validateFirmwareDate()]}
                placeholder="yyyymmdd"
                fullWidth
              />
            </Box>
          </Box>
          <FileInput
            source="firmwarebundleFileObj"
            label="Zipfile"
            accept="application/zip, application/x-zip-compressed, multipart/x-zip,*.zip"
          >
            <FileField source="url" title="title" />
          </FileInput>
          <CustomDivider />
          <Typography variant="h6" gutterBottom>
            Suspect Parameters & Failure Mode Identifiers
          </Typography>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
              <FileInput
                source="spnFileObj"
                label="SPN file"
                accept="application/json"
                multiple={false}
              >
                <FileField source="url" title="title" />
              </FileInput>
            </Box>
            <Box flex={1}>
              <FileInput
                source="fmiFileObj"
                label="FMI file"
                accept="application/json"
                multiple={false}
              >
                <FileField source="url" title="title" />
              </FileInput>
            </Box>
          </Box>
          <CustomDivider />
          <Typography variant="h6" gutterBottom>
            Documentation
          </Typography>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
              <TextInput source="documentationTitle" fullWidth />
            </Box>
            <Box flex={1}>
              <TextInput source="documentationType" fullWidth />
            </Box>
          </Box>

          <FileInput source="documentationFileObj" label="Documentation file">
            <FileField source="url" title="title" />
          </FileInput>

          <ImageInput source="productImageObj" label="Image">
            <ImageField source="url" />
          </ImageInput>
        </Box>
      </Box>
    </SimpleForm>
  );
};

export default ProductTypeEditDetails;
