import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ShowProps,
  EditButton,
  DeleteWithConfirmButton,
  TopToolbar,
  ListButton,
} from 'react-admin';

import GroupsField from './GroupsField';
import { UserTitle } from './UserTitle';

const UserShowActions = () => (
  <TopToolbar>
    <ListButton />
    <EditButton />
    <DeleteWithConfirmButton />
  </TopToolbar>
);

export const UserShow: React.FC<ShowProps> = (props) => {
  return (
    <Show title={<UserTitle />} actions={<UserShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="email" />
        <GroupsField />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
