import * as React from 'react';
import {
  useTranslate,
  useGetList,
  RecordContextProvider,
  useRecordContext,
} from 'react-admin';
import {
  Typography,
  Box,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { DateField } from '../components/DateField';
import { Product } from '../types';

const Aside = () => {
  const record = useRecordContext<Product>();
  return (
    <Box width={400} display={{ md: 'none', lg: 'block' }}>
      {record && <EventList />}
    </Box>
  );
};

const EventList = () => {
  const record = useRecordContext<Product>();
  const translate = useTranslate();
  const { data: history } = useGetList<Product>('products', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'meta.timestamp', order: 'DESC' },
    filter: { productSerial: record.productSerial, history: true },
  });

  return (
    <>
      <Box m="1em 0em 0em 2em">
        <Typography variant="h6">
          {translate('resources.products.fieldGroups.history')}
        </Typography>
      </Box>
      <Stepper
        orientation="vertical"
        sx={{
          background: 'none',
          border: 'none',
          marginLeft: '0.3em',
        }}
      >
        {history &&
          history.map((event) => (
            <Step key={event.id} expanded active completed>
              <StepLabel
                StepIconComponent={() => (
                  <EventIcon
                    fontSize="small"
                    color={event.id !== record.id ? 'disabled' : 'primary'}
                    style={{ paddingLeft: 3 }}
                  />
                )}
              >
                <DateField
                  showTime
                  locales="sv-SE"
                  record={{
                    id: event.id,
                    date: event.meta.timestamp,
                  }}
                  source="date"
                />
                &nbsp;-&nbsp;
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {event.runtimeHours.toFixed(2)}h
                </Typography>
              </StepLabel>
              <StepContent>
                <RecordContextProvider value={event}>
                  <History />
                </RecordContextProvider>
              </StepContent>
            </Step>
          ))}
      </Stepper>
    </>
  );
};

const History = () => {
  const record = useRecordContext<Product>();
  const { pathname } = useLocation();

  const parts = pathname.split('/');
  const tab = parts.length > 4 ? `/${parts[4]}` : '';
  return record ? (
    <>
      <Typography variant="body2" color="textSecondary">
        <Link
          data-cy="product-history-row"
          to={`/products/${encodeURIComponent(record.id)}/show${tab}`}
          component={RouterLink}
        >
          {record.meta.type}
        </Link>
        &nbsp;-&nbsp;{record.status}
      </Typography>

      <Typography variant="body2" gutterBottom>
        {record.meta.description}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        License: {record.meta.estLicensSerial} &nbsp;-&nbsp; Dongle:{' '}
        {record.meta.kvaserSerial}
      </Typography>
    </>
  ) : null;
};

export default Aside;
