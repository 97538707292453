import * as React from 'react';
import {
  AppBar,
  Logout,
  UserMenu,
  usePermissions,
  useTheme,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';

import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import Logo from './Logo';
import TenantSwitcher from './TenantSwitcher';

const ConfigurationMenu = React.forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/configuration"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate('pos.configuration')}</ListItemText>
    </MenuItem>
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const [theme] = useTheme();
  const { permissions } = usePermissions();
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm'),
  );

  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />
      {'nira' === (theme as any).name && <Logo />}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
      {permissions && permissions.includes('SystemAdmin') ? (
        <TenantSwitcher />
      ) : null}
    </AppBar>
  );
};

export default CustomAppBar;
