import { ApolloClient, gql } from '@apollo/client';
import { QueryGetFileUploadUrlArgs, FileType } from '../../../graphql/types';

export const GET_UPLOAD_URL = gql`
  query GetUploadUrl($productType: String!, $type: FileType!) {
    getFileUploadUrl(productType: $productType, type: $type) {
      fields
      url
      baseUrl
    }
  }
`;

export const getUploadUrl = async (
  client: ApolloClient<object>,
  productType: string,
  type: FileType,
) => {
  const variables = {
    productType,
    type,
  };

  return client
    .query<any, QueryGetFileUploadUrlArgs>({
      query: GET_UPLOAD_URL,
      variables,
      fetchPolicy: 'network-only',
    })
    .then((result) => {
      const { url, fields, baseUrl } = result.data.getFileUploadUrl;
      return { url, fields: JSON.parse(fields), baseUrl };
    });
};
