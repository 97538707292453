import React from 'react';
import { useAuthenticated, useGetList, useTheme } from 'react-admin';

import { Product } from '../graphql/types';

const styles = {
  flex: { display: 'flex' },
  flexContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '50%',
  },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Dashboard: React.FC = () => {
  useAuthenticated();
  const [theme]: any[] = useTheme();

  const { data } = useGetList<Product>('productslist', {
    sort: { field: 'meta.timestamp', order: 'DESC' },
    pagination: { page: 1, perPage: 100 },
  });

  const imgLogo = theme.logo ? (
    <img src={`/images/${theme.logo}`} alt={theme.logo} style={styles.logo} />
  ) : null;

  return <div style={styles.flexContainer}>{imgLogo}</div>;
};

export default Dashboard;
