export const fileUploader = async (
  url: string,
  fields: any,
  file: any,
  baseUrl: string,
) => {
  const form = new FormData();

  for (const key of Object.keys(fields)) {
    form.append(key, fields[key]);
  }

  form.append('file', file);

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Disposition': `file; filename="${file.name}"`,
    },
    body: form,
  })
    .then((response) => {
      return { response, baseUrl };
    })
    .catch((err) => {
      throw err;
    });
};
