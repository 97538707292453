import * as React from 'react';
import { FC } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';

interface LoadHeatMapProps {
  load: number[][] | null | undefined;
  loadX: number[] | null | undefined;
  loadY: number[] | null | undefined;
  caption: string;
  unitX: string;
  unitY: string;
}

interface LoadHeatMapState {
  historyLoadY: number[] | null | undefined;
  historyLoadX: number[] | null | undefined;
  historyLoad: number[][] | null | undefined;
  total: number;
  bins: string[];
  minVal: number;
  maxVal: number;
}

function colorFromBin(value: number, bins: string[]): string {
  const rStep = (239 - 69) / bins.length;
  const gStep = (250 - 183) / bins.length;
  const bStep = (243 - 83) / bins.length;
  const index = bins.indexOf(`${value.toFixed(3)}`);
  var h = (1.0 - value) * 240;
  return `rgb(${239 - rStep * index},${250 - gStep * index},${
    243 - bStep * index
  })`;
}

const LoadHeatMap: FC<LoadHeatMapProps> = ({
  load,
  loadX,
  loadY,
  caption,
  unitX,
  unitY,
}) => {
  const [state, setState] = React.useState<LoadHeatMapState>({
    historyLoadY: [],
    historyLoadX: [],
    historyLoad: [[]],
    total: 0,
    bins: [],
    minVal: 0,
    maxVal: 0,
  });

  React.useLayoutEffect(() => {
    if (!load) return;

    const historyLoadY = loadY && [...loadY];
    const historyLoadX = loadX && [...loadX];
    const historyLoad = load && load.map((a) => [...Array.from(a)]);

    const total = load
      .map((e: number[]) => e.reduce((acc, curr) => acc + curr))
      .reduce((acc, curr) => acc + curr);
    const values = load.flat().reduce((acc: Record<string, number>, curr) => {
      const key = `${curr.toFixed(3)}`;
      if (acc[key]) {
        acc[key] += 1;
      } else {
        acc[key] = 1;
      }
      return acc;
    }, {});
    const bins = Object.keys(values);
    bins.sort((a: string, b: string) => parseFloat(a) - parseFloat(b));
    const minVal = Math.min(...load.map((e: number[]) => Math.min(...e)));
    const maxVal = Math.max(...load.map((e: number[]) => Math.max(...e)));
    setState({
      historyLoadY: historyLoadY ? [...historyLoadY] : [],
      historyLoadX: historyLoadX ? [...historyLoadX] : [],
      historyLoad: [...historyLoad],
      bins,
      total,
      minVal,
      maxVal,
    });
  }, [load, loadX, loadY, unitY]);

  const { historyLoadY, historyLoadX, historyLoad, bins } = state;
  const headerCells = [
    ...(unitY
      ? [
          <TableCell
            key="unitY"
            padding="none"
            width="10%"
            style={{
              borderBottom: 'none',
              padding: '4px 4px 4px 4px',
            }}
          />,
        ]
      : []),
    ...(historyLoadX
      ? historyLoadX.map((x: number) => (
          <TableCell
            key={x}
            padding="none"
            width="10%"
            style={{
              borderBottom: 'none',
              padding: '4px 4px 4px 4px',
            }}
          >
            {x}&nbsp;{unitX}
          </TableCell>
        ))
      : []),
  ];

  const bodyRows = [
    ...(historyLoadY
      ? historyLoadY?.reverse().map((y, row) => {
          const cells = [
            ...(unitY
              ? [
                  <TableCell
                    key="unitY"
                    component="th"
                    scope="row"
                    align="right"
                    style={{
                      borderBottom: 'none',
                      padding: '4px 8px 4px 4px',
                    }}
                    padding="none"
                    children={`${y} ${unitY}`}
                  />,
                ]
              : []),
            ...(historyLoad
              ? historyLoad[row].reverse().map((v, r) => {
                  return (
                    <TableCell
                      key={`${y}-${r}`}
                      align="right"
                      style={{
                        backgroundColor: colorFromBin(v, bins),
                        borderBottom: 'none',
                        padding: '4px 4px 4px 4px',
                      }}
                      padding="none"
                      children={`${v.toFixed(3)}`}
                    />
                  );
                })
              : []),
          ];

          return <TableRow key={y}>{cells}</TableRow>;
        })
      : []),
  ];
  return (
    <>
      <Typography variant="h6">{caption}</Typography>
      <TableContainer>
        <Table size="medium" sx={{ maxWidth: '1020px' }} aria-label={caption}>
          <TableHead>
            <TableRow>{headerCells}</TableRow>
          </TableHead>
          <TableBody>{bodyRows}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LoadHeatMap;
