import * as React from 'react';
import { SelectArrayInput, SelectArrayInputProps } from 'react-admin';

import groups from '../groups/data';

const GroupsInput = (props: SelectArrayInputProps) => {
  return <SelectArrayInput {...props} source="groups" choices={groups} />;
};

export default GroupsInput;
