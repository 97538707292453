import { ProductType, ProductTypeExtended } from '../../types';
import { Fmi, Spn, Maybe } from '../../graphql/types';
import { getJwtLink, getAsFileObject, getTitle } from './utils';

export async function convertToProductTypeExtend(
  productType: ProductType,
): Promise<ProductTypeExtended> {
  const spn = productType.spn?.map(({ id, description, unit }) => ({
    id,
    description,
    unit,
  }));
  const fmi = productType.fmi?.map(({ id, description }) => ({
    id,
    description,
  }));
  const spnFileTitle = `spn_${productType.id}.json`;
  const fmiFileTitle = `fmi_${productType.id}.json`;
  const spnFile = asEmbeddedFile(productType.spn);
  const fmiFile = asEmbeddedFile(productType.fmi);
  return {
    ...productType,
    firmwarebundleFile: await getJwtLink(productType.firmwarebundleFile),
    documentationFile: await getJwtLink(productType.documentationFile),
    productImage: await getJwtLink(productType.productImage),
    spn,
    fmi,
    firmwarebundleFileObj: await getAsFileObject(
      productType.firmwarebundleFile,
    ),
    firmwarebundleFileTitle: getTitle(productType.firmwarebundleFile),
    documentationFileObj: await getAsFileObject(productType.documentationFile),
    documentationFileTitle: getTitle(productType.documentationFile),
    productImageObj: await getAsFileObject(productType.productImage),
    spnFileObj: await getAsFileObject(spnFile, spnFileTitle),
    spnFile,
    spnFileTitle,
    fmiFileObj: await getAsFileObject(fmiFile, fmiFileTitle),
    fmiFile,
    fmiFileTitle,
  };
}

export function asEmbeddedFile(
  list?: Maybe<Spn[]> | Maybe<Fmi[]>,
): string | undefined {
  return list
    ? `data:application/octet-stream;charset=utf-8;base64,${Buffer.from(
        JSON.stringify(list, null, 2),
      ).toString('base64')}`
    : undefined;
}
