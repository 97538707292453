import { getJwtLink, getTitle } from './';

export async function getAsFileObject(
  url?: string | undefined | null,
  title?: string,
  id = 1,
) {
  return url
    ? {
        id,
        url: (await getJwtLink(url)) ?? url,
        title: title ? title : getTitle(url),
      }
    : undefined;
}
