import * as React from 'react';
import { TextField, Labeled, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

interface ECUInfoProps {
  ecu: 'Master' | 'Slave';
  name?: string;
}

const ECUInfo: React.FC<ECUInfoProps> = ({ ecu, name }) => {
  const displayName = name ?? ecu;
  return (
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <Labeled label={`${displayName} Software ID`} sx={{ padding: '0.5em' }}>
          <TextField source={`softwareVersion${ecu}`} />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <Labeled label={`${displayName} ECU part no`} sx={{ padding: '0.5em' }}>
          <TextField source={`nvramEcuPartNo1${ecu}`} />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <Labeled
          label={`${displayName} ECU serial no`}
          sx={{ padding: '0.5em' }}
        >
          <TextField source={`ecuSerialNumber${ecu}`} />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <Labeled
          label={`${displayName} ECU Calibration ID`}
          sx={{ padding: '0.5em' }}
        >
          <TextField source={`calibrationVersion${ecu}`} />
        </Labeled>
      </Box>
    </Box>
  );
};

export default ECUInfo;
