import { ApolloClient, DocumentNode } from '@apollo/client';
import {
  FileObject,
  ProductTypeExtended,
  FMIFile,
  FMIFileRow,
  SPNFile,
  SPNFileRow,
} from '../../types';
import { ProductType, FileType, Fmi, Spn } from '../../graphql/types';
import { removeQueryPart, createUploadPromise } from './utils';

export function handleProductTypeMutation(
  client: ApolloClient<object>,
  params: any,
  mutation: DocumentNode,
) {
  const {
    data: {
      __typename,
      firmwarebundleFileObj,
      documentationFileObj,
      productImageObj,
      spnFileObj,
      fmiFileObj,
      firmwarebundleFileTitle,
      documentationFileTitle,
      spnFile,
      spnFileTitle,
      fmiFile,
      fmiFileTitle,
      ...data
    },
  }: { data: ProductTypeExtended } = params;

  if (spnFileObj === null) {
    data.spn = null;
  }
  if (fmiFileObj === null) {
    data.fmi = null;
  }

  data.firmwarebundleFile = removeQueryPart(data.firmwarebundleFile);
  data.documentationFile = removeQueryPart(data.documentationFile);
  data.productImage = removeQueryPart(data.productImage);

  //TODO: Delete files in S3 Bucket on removal
  // perhaps better to do this in the backend
  // using an event listener
  if (firmwarebundleFileObj === null) {
    data.firmwarebundleFile = null;
  }
  if (documentationFileObj === null) {
    data.documentationFile = null;
  }
  if (productImageObj === null) {
    data.productImage = null;
  }
  const promises = [
    spnFromFile(spnFileObj)?.then((spn) => {
      data.spn = spn;
    }),
    fmiFromFile(fmiFileObj)?.then((fmi) => {
      data.fmi = fmi;
    }),
    firmwarebundleFileObj
      ? createUploadPromise(
          client,
          data.id,
          FileType.Firmware,
          (value: string) => {
            data.firmwarebundleFile = value;
          },
          firmwarebundleFileObj.rawFile,
        )
      : undefined,
    documentationFileObj
      ? createUploadPromise(
          client,
          data.id,
          FileType.Docs,
          (value: string) => {
            data.documentationFile = value;
          },
          documentationFileObj.rawFile,
        )
      : undefined,
    productImageObj
      ? createUploadPromise(
          client,
          data.id,
          FileType.Image,
          (value: string) => {
            data.productImage = value;
          },
          productImageObj.rawFile,
        )
      : undefined,
  ].filter((p) => p !== undefined);

  return Promise.all(promises)
    .then(() => {
      return {
        input: {
          ...data,
        },
      };
    })
    .then((variables) => {
      return client.mutate({ mutation, variables });
    });
}

export function fmiFromFile(fileObj?: FileObject): Promise<Fmi[]> | undefined {
  return fileObj && fileObj.rawFile
    ? readAndParse<FMIFile>(fileObj)
        .then((fmi) =>
          fmi.failureModeIdentifier.map(([id, description]: FMIFileRow) => ({
            id,
            description,
          })),
        )
        .catch((e) => {
          throw new Error('Could not parse FMI file.');
        })
    : undefined;
}

export function spnFromFile(fileObj?: FileObject): Promise<Spn[]> | undefined {
  return fileObj && fileObj.rawFile
    ? readAndParse<SPNFile>(fileObj)
        .then((spn) =>
          spn.suspectParameterNumber.map(
            ([id, description, unit]: SPNFileRow) => ({
              id,
              description,
              unit,
            }),
          ),
        )
        .catch((e) => {
          throw new Error('Could not parse SPN file.');
        })
    : undefined;
}

export function readAndParse<T>(fileObj: FileObject): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(fileObj.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }).then((content: any) => JSON.parse(content)) as Promise<T>;
}
