import * as React from 'react';
import Box from '@mui/material/Box';
import DefaultIcon from '@mui/icons-material/ViewList';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from 'react-admin';

import users from '../users';
import products from '../products';

const AUTHORIZED_GROUPS = ['SystemAdmin', 'TenantAdmin'];

const Menu = ({ dense = false }: MenuProps) => {
  const translate = useTranslate();
  const [open] = useSidebarState();

  const { permissions }: { permissions?: string[] } = usePermissions();
  const previlegedGroup =
    (permissions && permissions.some((p) => AUTHORIZED_GROUPS.includes(p))) ||
    false;

  return (
    <Box
      sx={{
        width: open ? 240 : 55,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {previlegedGroup && (
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          dense={dense}
        />
      )}
      {previlegedGroup && (
        <MenuItemLink
          to="/productTypes"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.producttypes.name`, {
            smart_count: 2,
          })}
          leftIcon={<DefaultIcon />}
          dense={dense}
        />
      )}
      <MenuItemLink
        to="/products"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.products.name`, {
          smart_count: 2,
        })}
        leftIcon={<products.icon />}
        dense={dense}
      />
    </Box>
  );
};

export default Menu;
