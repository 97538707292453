import * as React from 'react';
import {
  TabbedShowLayout,
  Tab,
  Show,
  ShowProps,
  TextField,
  ImageField,
  useShowController,
  Labeled,
  useNotify,
  useRecordContext,
  RecordContextProvider,
} from 'react-admin';
import { Typography, Card, CardContent, Box } from '@mui/material';
import { getApolloContext } from '@apollo/client';
import BuildDataProvider from '../dataProvider';

import { DateField } from '../components/DateField';
import StatusField from './StatusField';
import Aside from './Aside';
import Statistics from './statistics';
import Notifications from './notifications';
import Components from './Components';
import AdjustmentChannels from './AdjustmentChannels';
import ProductType from './ProductType';
import ECUInfo from './ECUInfo';

import { Product, ProductTypeExtended } from '../types';

const ProductTitle = () => {
  const record = useRecordContext<Product>();
  return <span>Product {record ? `${record.productSerial}` : ''}</span>;
};

const ProductShow: React.FC<ShowProps> = (props) => {
  const [productType, setProductType] = React.useState<
    ProductTypeExtended | undefined
  >(undefined);
  const { client } = React.useContext(getApolloContext());

  const { record } = useShowController<Product>();

  const notify = useNotify();

  React.useEffect(() => {
    const fetchData = async (id: string) => {
      await BuildDataProvider(client!, false)
        .getOne<ProductTypeExtended>('productTypes', {
          id,
        })
        .then(({ data }) => {
          setProductType(data);
        })
        .catch((error) => {
          notify(
            `Could not fetch product type: [${record?.productType}]. It may not exist. Error: [${error}]`,
            {
              type: 'error',
              messageArgs: {
                _: `Could not fetch product type: [${record?.productType}]. It may not exist. Error: [${error}]`,
              },
            },
          );
        });
    };
    if (record && !productType) {
      fetchData(record.productType);
    }
  }, [client, record, productType, notify]);

  const firstEcuDisplayName =
    record?.eCUInfos && record.eCUInfos.length > 0
      ? record.eCUInfos[0].name
      : 'Master';
  const secondEcuDisplayName =
    record?.eCUInfos && record.eCUInfos.length > 1
      ? record.eCUInfos[1].name
      : 'Slave';

  const disableStatistics =
    record?.eCUInfos && record.eCUInfos.length > 0
      ? record.eCUInfos[0].hideStatistics ?? false
      : false;
  return (
    <Show title={<ProductTitle />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label="resources.products.tabs.information">
          <Card>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    Details
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <Labeled label="Type" sx={{ padding: 1 }}>
                        <TextField source="productType" />
                      </Labeled>
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <Labeled label="Rating" sx={{ padding: 1 }}>
                        <TextField source="productRating" />
                      </Labeled>
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <Labeled label="Engine Serial Number" sx={{ padding: 1 }}>
                        <TextField source="engineSerialNumber" />
                      </Labeled>
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <Labeled label="Engine runtime" sx={{ padding: 1 }}>
                        <Typography variant="body2">
                          {record && record.runtimeHours.toFixed(2)}h
                        </Typography>
                      </Labeled>
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <Labeled source="yearOfManufacturing" sx={{ padding: 1 }}>
                        <TextField source="yearOfManufacturing" />
                      </Labeled>
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <Labeled source="lastUpdate" sx={{ padding: 1 }}>
                        <DateField
                          source="lastUpdate"
                          showTime
                          locales="sv-SE"
                        />
                      </Labeled>
                    </Box>
                  </Box>
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: '1em' }}
                  mt={{ xs: '1em', lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Status
                  </Typography>
                  <div>
                    <StatusField />
                  </div>
                  <ImageField
                    source="productImage"
                    record={productType}
                    sx={{ marginTop: '1em' }}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <ECUInfo ecu="Master" name={firstEcuDisplayName} />
                  {record?.ecuSerialNumberSlave && <ECUInfo ecu="Slave" />}
                </Box>
              </Box>
            </CardContent>
          </Card>
          <RecordContextProvider value={productType}>
            <ProductType />
          </RecordContextProvider>
        </Tab>
        <Tab label="resources.products.tabs.notifications">
          <Notifications />
        </Tab>
        <Tab
          label="resources.products.tabs.statistics"
          disabled={disableStatistics}
        >
          <Statistics />
        </Tab>
        <Tab label="resources.products.tabs.components">
          <Components />
        </Tab>
        <Tab label="resources.products.tabs.adjustments">
          <AdjustmentChannels />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ProductShow;
