import * as React from 'react';
import { Stack, Chip } from '@mui/material';
import { useTranslate, FieldProps, useRecordContext } from 'react-admin';
import groups from '../groups/data';
import { User } from '../graphql/types';

const GroupsField = (props: FieldProps) => {
  const translate = useTranslate();
  const record = useRecordContext<User>();
  if (!record || !record.groups) {
    return null;
  }
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {record.groups.map((groupId) => {
        const group = groups.find((s) => s.id === groupId);
        return group ? (
          <Chip size="small" key={groupId} label={translate(group.name)} />
        ) : null;
      })}
    </Stack>
  );
};

export default GroupsField;
