import * as React from 'react';
import { FC } from 'react';

import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Fault, FreezeFrameField } from '../../types';
import ExpandRowButton from './ExpandRowButton';
import FreezeFrames from './FreezeFrames';

interface FaultsViewProps {
  faults: Fault[] | null | undefined;
  freezeFrameFields: FreezeFrameField[] | null | undefined;
  caption: string;
}

const FaultsView: FC<FaultsViewProps> = ({
  faults,
  freezeFrameFields,
  caption,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState<boolean[]>(
    faults ? Array.from(faults, (f) => false) : [],
  );

  React.useLayoutEffect(() => {
    setExpanded(faults ? Array.from(faults, (f) => false) : []);
  }, [location, setExpanded, faults]);

  return (
    <>
      <Typography variant="h6">{caption}</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>SPN</TableCell>
              <TableCell>FMI</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Occurrences</TableCell>
              <TableCell>Engine Runtime</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faults?.map((f, i) => {
              const handleClick = async (event: any) => {
                if (
                  faults[i].freezeFrames &&
                  faults[i].freezeFrames!.length > 0
                ) {
                  setExpanded((e) => {
                    e[i] = !e[i];
                    return { ...e };
                  });
                  event.stopPropagation();
                }
              };

              const disabled =
                !faults[i].freezeFrames || faults[i].freezeFrames?.length === 0;
              return (
                <React.Fragment key={`${f.spn}-${f.fmi}`}>
                  <TableRow hover={true} onClick={handleClick}>
                    <TableCell padding="none">
                      <ExpandRowButton
                        expanded={expanded[i]}
                        onClick={handleClick}
                        disabled={disabled}
                      />
                    </TableCell>
                    <TableCell>{f.spn}</TableCell>
                    <TableCell>{f.fmi}</TableCell>
                    <TableCell>{f.description}</TableCell>
                    <TableCell>{f.oc}</TableCell>
                    <TableCell>{f.engineRuntime}</TableCell>
                  </TableRow>
                  {expanded[i] && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <FreezeFrames
                          freezeFrames={f.freezeFrames}
                          freezeFrameFields={freezeFrameFields}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FaultsView;
