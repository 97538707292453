import ProductTypeList from './ProductTypeList';
import ProductTypeShow from './ProductTypeShow';
import ProductTypeEdit from './ProductTypeEdit';
import ProductTypeCreate from './ProductTypeCreate';

const Handlers = {
  list: ProductTypeList,
  show: ProductTypeShow,
  create: ProductTypeCreate,
  edit: ProductTypeEdit,
};

export default Handlers;
