import * as React from 'react';
import {
  Typography,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Box,
} from '@mui/material';
import { DateField, useRecordContext } from 'react-admin';

import { Product } from '../types';

const Components = () => {
  const record = useRecordContext<Product>();
  return (
    <Card>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6">Components</Typography>
            <TableContainer>
              <Table size="small" sx={{ padding: 1 }} aria-label="Components">
                <TableHead>
                  <TableRow>
                    <TableCell>Part</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Nof Replacements</TableCell>
                    <TableCell>Last Replacement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.productParts?.map((pp) => (
                    <TableRow key={pp.position}>
                      <TableCell>{pp.name}</TableCell>
                      <TableCell>{pp.serial}</TableCell>
                      <TableCell>{pp.nofReplacements}</TableCell>
                      <TableCell width="25%">
                        {pp.lastReplacement ? (
                          <DateField
                            showTime
                            locales="sv-SE"
                            record={{
                              id: pp.position,
                              date: pp.lastReplacement,
                            }}
                            source="date"
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Components;
