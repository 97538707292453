import * as React from 'react';
import { Datagrid, List, ListProps, TextField } from 'react-admin';

const ProductTypeList: React.FC<ListProps> = (props) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" label="Name" />
      <TextField source="rating" />
      <TextField source="firmwarebundleVersion" />
      <TextField source="firmwarebundleDate" />
      <TextField source="noOfPumps" label="Number of Pumps" />
      <TextField source="noOfInjectors" label="Number of Injectors" />
      <TextField source="noOfECUs" label="Number of ECUs" />
    </Datagrid>
  </List>
);

export default ProductTypeList;
