import * as React from 'react';
import {
  Edit,
  EditProps,
  TopToolbar,
  ShowButton,
  ListButton,
} from 'react-admin';
import { ProductTypeTitle } from './ProductTypeTitle';

import ProductTypeEditDetails from './ProductTypeEditDetails';

const ProductTypeEditActions = () => (
  <TopToolbar>
    <ListButton />
    <ShowButton />
  </TopToolbar>
);

export const ProductTypeEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<ProductTypeTitle />}
      actions={<ProductTypeEditActions />}
      sx={{ maxWidth: '800px' }}
    >
      <ProductTypeEditDetails />
    </Edit>
  );
};

export default ProductTypeEdit;
