import { AuthProvider } from 'react-admin';
import { Auth } from 'aws-amplify';

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    return Auth.signIn(username, password);
  },
  logout: () => Auth.signOut(),
  checkError: (error) => {
    const status =
      error && error.networkError ? error.networkError.statusCode : 0;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return Auth.currentSession()
      .then(() => Promise.resolve())
      .catch(() => Promise.reject());
  },
  getPermissions: () => {
    return Auth.currentSession()
      .then((session) => {
        const groups =
          session.getAccessToken().decodePayload()['cognito:groups'] || [];
        return Promise.resolve(groups);
      })
      .catch(() => Promise.reject());
  },
  getIdentity: () =>
    Auth.currentUserInfo()
      .then((info) => {
        return Promise.resolve({
          id: info.attributes.sub,
          fullName: info.attributes.name,
          tenantId: info.attributes['custom:tenantId'],
        });
      })
      .catch(() => Promise.reject()),
};

export default authProvider;
