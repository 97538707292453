export function log(type: any, resource: any, params: any, response: any) {
  // eslint-disable-next-line no-console
  if (console.group !== undefined) {
    // Better logging in Chrome
    // eslint-disable-next-line no-console
    console.groupCollapsed(type, resource, JSON.stringify(params));
    // eslint-disable-next-line no-console
    console.log(response);
    // eslint-disable-next-line no-console
    console.groupEnd();
  } else {
    // eslint-disable-next-line no-console
    console.log('Request', type, resource, params);
    // eslint-disable-next-line no-console
    console.log('Response', response);
  }
}
