import * as React from 'react';
import {
  Typography,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Box,
} from '@mui/material';
import { useRecordContext } from 'react-admin';
import { Product } from '../types';

const AdjustmentChannels = () => {
  const record = useRecordContext<Product>();
  return record?.adjustmentChannels ? (
    <Card>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6">Adjustment Channels</Typography>
            <TableContainer>
              <Table size="small" aria-label="AdjustmentChannels">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Identifier</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.adjustmentChannels?.contents?.map((ac) => (
                    <TableRow key={ac.identifier}>
                      <TableCell>{ac.name}</TableCell>
                      <TableCell>{ac.identifier}</TableCell>
                      <TableCell>{ac.value}</TableCell>
                      <TableCell width="25%">{ac.unit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6">Adjustment Channels</Typography>
            No adjustment channels loaded for this product
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AdjustmentChannels;
