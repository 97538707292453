import * as React from 'react';
import { FC } from 'react';

import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';

import { FreezeFrame, FreezeFrameField } from '../../types';

interface FreezeFramesProps {
  freezeFrames: FreezeFrame[] | null | undefined;
  freezeFrameFields: FreezeFrameField[] | null | undefined;
}

const FreezeFrames: FC<FreezeFramesProps> = ({
  freezeFrames,
  freezeFrameFields,
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="none" width="20%">
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                Occurrences
              </Typography>
            </TableCell>
            {freezeFrames?.map((ff: FreezeFrame) => (
              <TableCell key={ff.spn} padding="none" width="10%">
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ff.oc}
                </Typography>
              </TableCell>
            ))}
            <TableCell padding="none">&nbsp;</TableCell>
            <TableCell padding="none" width="10%">
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                Unit
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {freezeFrameFields?.map((ff: FreezeFrameField, index) => {
            return (
              <TableRow key={ff.id}>
                <TableCell padding="none">{ff.description}</TableCell>
                {freezeFrames?.map((f: FreezeFrame) => (
                  <TableCell
                    key={`${f.spn}-${f.spn}`}
                    padding="none"
                    width="10%"
                  >
                    {Number.isInteger(f.values![index])
                      ? f.values![index]
                      : f.values![index].toFixed(2)}
                  </TableCell>
                ))}
                <TableCell padding="none"></TableCell>
                <TableCell padding="none">{ff.unit}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FreezeFrames;
