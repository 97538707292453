import {
  lightTheme,
  darkTheme,
  koelTheme,
  caudwellTheme,
  coxTheme,
} from './themes';

export const getTheme = (name: string | undefined) => {
  switch (name) {
    case 'dark': {
      return darkTheme;
    }
    case 'koel': {
      return koelTheme;
    }
    case 'caudwell': {
      return caudwellTheme;
    }
    case 'cox': {
      return coxTheme;
    }
    default: {
      return lightTheme;
    }
  }
};
