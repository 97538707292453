import { DataProvider } from 'react-admin';
import { ApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetProductType, ProductType } from '../types';
import { QueryGetProductArgs } from '../graphql/types';
import {
  handleProductTypeMutation,
  convertToProductTypeExtend,
} from './resources';
import { dynamicSort } from './resources/utils';
import { log } from './logging';

const buildDataProvider = (
  client: ApolloClient<object>,
  loggingEnabled = false,
): DataProvider => {
  function getResponse(type: any, resource: any, params: any): Promise<any> {
    const typeResource = `${type}-${resource}`;

    switch (typeResource) {
      case 'getList-users': {
        const query = loader('./queries/getUsers.graphql');
        return client
          .query({ query, fetchPolicy: 'network-only' })
          .then((result) => {
            const data = result.data.getUsers.users ?? [];
            return {
              data,
              total: data.length,
            };
          });
      }
      case 'getOne-users': {
        const variables = {
          id: params.id,
        };
        const query = loader('./queries/getUser.graphql');
        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((result) => {
            return {
              data: result.data.getUser,
            };
          });
      }
      case 'create-users': {
        const { confirm_password, ...input } = params.data;
        const variables = {
          input,
        };
        const mutation = loader('./queries/createUser.graphql');

        return client.mutate({ mutation, variables }).then((result) => {
          return {
            data: result.data.createUser,
          };
        });
      }
      case 'update-users': {
        const { id, confirm_password, __typename, ...input } = params.data;
        const variables = {
          id,
          input,
        };
        const mutation = loader('./queries/updateUser.graphql');
        return client.mutate({ mutation, variables }).then((result) => {
          return {
            data: result.data.updateUser,
          };
        });
      }
      case 'delete-users': {
        const variables = {
          id: params.id,
        };
        const mutation = loader('./queries/deleteUser.graphql');

        return client.mutate({ mutation, variables }).then((result) => {
          return {
            data: result.data.deleteUser,
          };
        });
      }
      case 'getList-tenants': {
        const variables = {
          limit: 25,
        };
        const query = loader('./queries/getTenants.graphql');
        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((result) => {
            const tenants = result.data.getTenants.tenants ?? [];
            return {
              data: tenants,
              total: tenants.length,
            };
          });
      }
      case 'getList-productshistory': {
        const { productSerial } = params.filter;
        const variables = {
          productSerial,
          limit: 100,
        };
        const query = loader('./queries/getProductsHistory.graphql');
        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((result) => {
            const history = result.data.getProductsHistory.products || [];

            return {
              data: history,
              total: history.length,
            };
          });
      }
      case 'getList-products': {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { productSerial } = params.filter;

        const variables = {
          ...(productSerial ? { productSerial } : {}),
          limit: 300,
        };
        const query = loader('./queries/getProductsHistory.graphql');

        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((result) => {
            let products = result.data.getProducts.products || [];

            if (!field.includes('.')) {
              products = products.slice().sort(dynamicSort(field, order));
            }

            const showedProducts = products.slice(
              (page - 1) * perPage,
              page * perPage,
            );
            return {
              data: showedProducts,
              total: products.length,
            };
          });
      }
      case 'getList-productslist': {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { productSerial, search } = params.filter;

        const variables = {
          ...(productSerial ? { productSerial } : {}),
          ...(search ? { search } : {}),
          limit: 600,
        };
        const query = loader('./queries/getProducts.graphql');
        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((result) => {
            let products = result.data.getProducts.products || [];

            if (!field.includes('.')) {
              products = products.slice().sort(dynamicSort(field, order));
            }

            const showedProducts = products.slice(
              (page - 1) * perPage,
              page * perPage,
            );
            return {
              data: showedProducts,
              total: products.length,
            };
          });
      }
      case 'getOne-products': {
        const variables = {
          id: params.id,
        };

        const query = loader('./queries/getProductHistory.graphql');
        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((result) => {
            return {
              data: result.data.getProductHistory,
            };
          });
      }
      case 'getList-productTypes': {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const variables = {
          limit: 100,
        };
        const query = loader('./queries/getProductTypes.graphql');
        return client
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then(async (result) => {
            const productTypes: ProductType[] = [
              ...(result.data.getProductTypes.productTypes || []),
            ];
            productTypes.sort(dynamicSort(field, order));

            const showedProductTypes = productTypes.slice(
              (page - 1) * perPage,
              page * perPage,
            );
            return {
              data: await Promise.all(
                showedProductTypes.map(
                  async (pt) => await convertToProductTypeExtend(pt),
                ),
              ),
              total: productTypes.length,
            };
          });
      }
      case 'create-productTypes': {
        const mutation = loader('./queries/createProductType.graphql');
        return handleProductTypeMutation(client, params, mutation).then(
          async ({ data }) => {
            return {
              data: await convertToProductTypeExtend(data.createProductType),
            };
          },
        );
      }
      case 'update-productTypes': {
        const mutation = loader('./queries/updateProductType.graphql');
        return handleProductTypeMutation(client, params, mutation).then(
          async ({ data }) => {
            return {
              data: await convertToProductTypeExtend(data.updateProductType),
            };
          },
        );
      }
      case 'delete-productTypes': {
        const variables = {
          id: params.id,
        };
        const mutation = loader('./queries/deleteProductType.graphql');

        return client.mutate({ mutation, variables }).then((result) => {
          return {
            data: result.data.deleteProductType,
          };
        });
      }
      case 'getOne-productTypes': {
        const variables: QueryGetProductArgs = {
          id: params.id,
        };
        const query = loader('./queries/getProductType.graphql');
        return client
          .query<GetProductType, QueryGetProductArgs>({
            query,
            variables,
            fetchPolicy: 'network-only',
          })
          .then(async ({ data }) => {
            return {
              data: await convertToProductTypeExtend(data.getProductType!),
            };
          });
      }
      default:
        return Promise.reject(`Nothing is mapped for ${typeResource} `);
    }
  }

  const handle = (type: string, resource: any, params: any): Promise<any> => {
    let response;
    try {
      response = getResponse(type, resource, params);
    } catch (error) {
      return Promise.reject(error);
    }
    if (loggingEnabled) {
      log(type, resource, params, response);
    }
    return Promise.resolve(response);
  };

  return {
    getList: (resource, params) => handle('getList', resource, params),
    getOne: (resource, params) => handle('getOne', resource, params),
    getMany: (resource, params) => handle('getMany', resource, params),
    getManyReference: (resource, params) =>
      handle('getManyReference', resource, params),
    update: (resource, params) => handle('update', resource, params),
    updateMany: (resource, params) => handle('updateMany', resource, params),
    create: (resource, params) => handle('create', resource, params),
    delete: (resource, params) => handle('delete', resource, params),
    deleteMany: (resource, params) => handle('deleteMany', resource, params),
  };
};

export default buildDataProvider;
