import * as React from 'react';
import { getTheme } from './getTheme';
import { getApolloContext } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Auth } from 'aws-amplify';
import { Layout, LayoutProps, useTheme } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';

const LayoutWrapper = (props: LayoutProps) => {
  const [theme, setTheme] = useTheme();
  const { client } = React.useContext(getApolloContext());
  const query = loader('../dataProvider/queries/getTheme.graphql');

  React.useLayoutEffect(() => {
    Auth.currentSession()
      .then(async (session) => {
        client?.query({ query, fetchPolicy: 'network-only' }).then((result) => {
          const newTheme = result.data.getTenant.theme || 'light';
          if (newTheme !== theme) setTheme(getTheme(newTheme));
        });
      })
      .catch(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return <Layout {...props} appBar={AppBar} menu={Menu} />;
};

export default LayoutWrapper;
