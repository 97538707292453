import * as React from 'react';
import {
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  TextField,
  FunctionField,
  SearchInput,
  RowClickFunction,
  useCreatePath,
} from 'react-admin';

import { DateField } from '../components/DateField';
import StatusField from './StatusField';

const ProductFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput data-cy="product-search-input" source="search" alwaysOn />
  </Filter>
);

const ProductList: React.FC<ListProps> = (props) => {
  const createPath = useCreatePath();
  const rowClick: RowClickFunction = (id, basePath, record) => {
    const recordId = `${record.productSerial}#${record.timestamp}`;
    const link = createPath({
      type: 'show',
      resource: 'products',
      id: recordId,
    });
    return link;
  };
  return (
    <List
      {...props}
      resource="productslist"
      title="Products"
      filters={<ProductFilter />}
    >
      <Datagrid rowClick={rowClick} bulkActionButtons={false}>
        <TextField data-cy="product-row" source="productSerial" />
        <StatusField />
        <FunctionField
          label="Online"
          render={(record: any) => (record.online === true ? 'Yes' : 'No')}
        />
        <DateField source="lastUpdate" showTime locales="sv-SE" />
        <FunctionField
          label="Runtime Hours"
          render={(record: any) => `${record.runtimeHours.toFixed(2)}h`}
        />
      </Datagrid>
    </List>
  );
};

export default ProductList;
