export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: any;
  AWSJSON: any;
  AWSURL: any;
  AWSEmail: string;
  AWSDate: Date;
  AWSTime: any;
  AWSTimestamp: any;
  AWSPhone: any;
  AWSIPAddress: any;
  BigInt: any;
  Double: any;
};

export type AdjustmentChannel = {
  __typename?: 'AdjustmentChannel';
  formatVersion: Scalars['String'];
  contents?: Maybe<Array<AdjustmentChannelRow>>;
};

export type AdjustmentChannelRow = {
  __typename?: 'AdjustmentChannelRow';
  name: Scalars['String'];
  identifier: Scalars['Int'];
  unit: Scalars['String'];
  value: Scalars['String'];
};

export type CreateTenantInput = {
  name: Scalars['String'];
  domain: Scalars['String'];
  adminUsers?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Scalars['String']>>;
};

export type EcuInfo = {
  __typename?: 'ECUInfo';
  name: Scalars['String'];
  hardware: Scalars['String'];
  canAddress: Scalars['String'];
  hideStatistics?: Maybe<Scalars['Boolean']>;
  engineDataView?: Maybe<Scalars['Int']>;
};

export type Fault = {
  __typename?: 'Fault';
  spn: Scalars['Int'];
  fmi: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  oc: Scalars['Int'];
  engineRuntime?: Maybe<Scalars['Float']>;
  freezeFrames?: Maybe<Array<FreezeFrame>>;
};

export enum FileType {
  Image = 'image',
  Firmware = 'firmware',
  Docs = 'docs',
}

export type Fmi = {
  __typename?: 'FMI';
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type FmiInput = {
  id: Scalars['Int'];
  description: Scalars['String'];
};

export type FreezeFrame = {
  __typename?: 'FreezeFrame';
  spn: Scalars['Int'];
  fmi: Scalars['Int'];
  oc: Scalars['Int'];
  values?: Maybe<Array<Scalars['Float']>>;
};

export type FreezeFrameField = {
  __typename?: 'FreezeFrameField';
  id: Scalars['Int'];
  description: Scalars['String'];
  unit: Scalars['String'];
};

export type Injector = {
  __typename?: 'Injector';
  position: Scalars['Int'];
  serial?: Maybe<Scalars['String']>;
  trimCode?: Maybe<Scalars['String']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  type: Scalars['String'];
  serviceType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  estLicensSerial: Scalars['String'];
  kvaserSerial: Scalars['String'];
  timeZone: Scalars['Int'];
  timestamp: Scalars['AWSDateTime'];
  fileVersion?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser: User;
  deleteUser: User;
  assumeTenant?: Maybe<Scalars['ID']>;
  createTenant?: Maybe<Tenant>;
  createProductType?: Maybe<ProductType>;
  updateProductType?: Maybe<ProductType>;
  deleteProductType?: Maybe<ProductType>;
};

export type MutationCreateUserArgs = {
  input: UserInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserInput;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationAssumeTenantArgs = {
  tenantId: Scalars['ID'];
};

export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};

export type MutationCreateProductTypeArgs = {
  input: ProductTypeInput;
};

export type MutationUpdateProductTypeArgs = {
  input: ProductTypeInput;
};

export type MutationDeleteProductTypeArgs = {
  id: Scalars['ID'];
};

export type PresignedPost = {
  __typename?: 'PresignedPost';
  url: Scalars['String'];
  fields: Scalars['AWSJSON'];
  baseUrl: Scalars['AWSURL'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  meta: MetaData;
  status: Scalars['String'];
  timestamp: Scalars['AWSDateTime'];
  productRating: Scalars['String'];
  productType: Scalars['String'];
  productSerial: Scalars['String'];
  yearOfManufacturing: Scalars['String'];
  engineSerialNumber: Scalars['String'];
  ecuSerialNumberMaster: Scalars['String'];
  ecuSerialNumberSlave?: Maybe<Scalars['String']>;
  fuelpumpSerialMaster?: Maybe<Scalars['String']>;
  fuelpumpSerialSlave?: Maybe<Scalars['String']>;
  injectorSerial?: Maybe<Array<Scalars['String']>>;
  injectors?: Maybe<Array<Injector>>;
  softwareVersionMaster: Scalars['String'];
  softwareVersionSlave?: Maybe<Scalars['String']>;
  calibrationVersionMaster: Scalars['String'];
  calibrationVersionSlave?: Maybe<Scalars['String']>;
  freezeFrameFields?: Maybe<Array<FreezeFrameField>>;
  freezeFramesMaster?: Maybe<Array<FreezeFrame>>;
  freezeFramesSlave?: Maybe<Array<FreezeFrame>>;
  online: Scalars['Boolean'];
  realtimeUpdates: Scalars['String'];
  lastUpdate: Scalars['AWSDateTime'];
  runtimeHours: Scalars['Float'];
  estLicense: Scalars['String'];
  dongleSerial: Scalars['String'];
  productParts?: Maybe<Array<ProductPart>>;
  nvramEcuPartNo1Master?: Maybe<Scalars['String']>;
  nvramEcuPartNo1Slave?: Maybe<Scalars['String']>;
  activeFaultsMaster?: Maybe<Array<Fault>>;
  activeFaultsSlave?: Maybe<Array<Fault>>;
  previousActiveFaultsMaster?: Maybe<Array<Fault>>;
  previousActiveFaultsSlave?: Maybe<Array<Fault>>;
  historyEngineLoad?: Maybe<Array<Array<Scalars['Float']>>>;
  historyEngineLoadX?: Maybe<Array<Scalars['Float']>>;
  historyEngineLoadY?: Maybe<Array<Scalars['Float']>>;
  historyFuelPressure?: Maybe<Array<Array<Scalars['Float']>>>;
  historyFuelPressureX?: Maybe<Array<Scalars['Float']>>;
  historyFuelPressureY?: Maybe<Array<Scalars['Float']>>;
  historyOilPressure?: Maybe<Array<Array<Scalars['Float']>>>;
  historyOilPressureX?: Maybe<Array<Scalars['Float']>>;
  historyOilPressureY?: Maybe<Array<Scalars['Float']>>;
  hstAirPressureMaxPeak?: Maybe<Scalars['Float']>;
  hstAirPressureMaxTime?: Maybe<Scalars['Float']>;
  hstAirTempMaxPeak?: Maybe<Scalars['Float']>;
  hstAirTempMaxTime?: Maybe<Scalars['Float']>;
  hstAirTempMinPeak?: Maybe<Scalars['Float']>;
  hstAirTempMinTime?: Maybe<Scalars['Float']>;
  hstCoolantTempMaxPeak?: Maybe<Scalars['Float']>;
  hstCoolantTempMaxTime?: Maybe<Scalars['Float']>;
  hstCoolantTempMinPeak?: Maybe<Scalars['Float']>;
  hstCoolantTempMinTime?: Maybe<Scalars['Float']>;
  hstEngineSpeedMaxPeak?: Maybe<Scalars['Float']>;
  hstEngineSpeedMaxTime?: Maybe<Scalars['Float']>;
  hstFuelPressureMaxPeak?: Maybe<Scalars['Float']>;
  hstFuelPressureMaxTime?: Maybe<Scalars['Float']>;
  hstFuelTempMaxPeak?: Maybe<Scalars['Float']>;
  hstFuelTempMaxTime?: Maybe<Scalars['Float']>;
  hstFuelTempMinPeak?: Maybe<Scalars['Float']>;
  hstFuelTempMinTime?: Maybe<Scalars['Float']>;
  hstOilPressureMaxPeak?: Maybe<Scalars['Float']>;
  hstOilPressureMaxTime?: Maybe<Scalars['Float']>;
  hstOilPressureMinPeak?: Maybe<Scalars['Float']>;
  hstOilPressureMinTime?: Maybe<Scalars['Float']>;
  hstEngineRunTimeMaster?: Maybe<Scalars['Float']>;
  hstEngineRunTimeSlave?: Maybe<Scalars['Float']>;
  nvramEscDGainAdj?: Maybe<Scalars['Float']>;
  nvramEscIGainAdj?: Maybe<Scalars['Float']>;
  nvramEscPGainAdj?: Maybe<Scalars['Float']>;
  nvramEscIdleSpeedAdj?: Maybe<Scalars['Float']>;
  nvramEscWarmUpAdj?: Maybe<Scalars['Float']>;
  nvramGcuDroopOffsetAdj?: Maybe<Scalars['Float']>;
  nvramGcuIdleTimeAdj?: Maybe<Scalars['Float']>;
  nvramGcuLoadSpeedAdj?: Maybe<Scalars['Float']>;
  nvramGcuSyncOverride?: Maybe<Scalars['Float']>;
  nvramCiModel?: Maybe<Scalars['String']>;
  nvramCiSerialNr?: Maybe<Scalars['String']>;
  nvramCiUnitNr?: Maybe<Scalars['String']>;
  nvramEcuSerialNoMaster?: Maybe<Scalars['String']>;
  nvramEcuSerialNoSlave?: Maybe<Scalars['String']>;
  adjustmentChannels?: Maybe<AdjustmentChannel>;
  eCUInfos?: Maybe<Array<EcuInfo>>;
};

export type ProductListItem = {
  __typename?: 'ProductListItem';
  id: Scalars['ID'];
  productSerial: Scalars['String'];
  status: Scalars['String'];
  timestamp: Scalars['AWSDateTime'];
  online: Scalars['Boolean'];
  lastUpdate: Scalars['AWSDateTime'];
  runtimeHours: Scalars['Float'];
  productRating: Scalars['String'];
  productType: Scalars['String'];
  realtimeUpdates: Scalars['String'];
  yearOfManufacturing: Scalars['String'];
};

export type ProductPart = {
  __typename?: 'ProductPart';
  name: Scalars['String'];
  nofReplacements: Scalars['Int'];
  lastReplacement?: Maybe<Scalars['AWSDateTime']>;
  position: Scalars['Int'];
  serial?: Maybe<Scalars['String']>;
  spn?: Maybe<Scalars['String']>;
};

export type ProductsListPage = {
  __typename?: 'ProductsListPage';
  products?: Maybe<Array<ProductListItem>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ProductsPage = {
  __typename?: 'ProductsPage';
  products?: Maybe<Array<Product>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['ID'];
  rating: Scalars['String'];
  noOfInjectors: Scalars['Int'];
  noOfPumps: Scalars['Int'];
  noOfECUs: Scalars['Int'];
  firmwarebundleFile?: Maybe<Scalars['String']>;
  firmwarebundleVersion?: Maybe<Scalars['String']>;
  firmwarebundleDate?: Maybe<Scalars['String']>;
  spn?: Maybe<Array<Spn>>;
  fmi?: Maybe<Array<Fmi>>;
  documentationFile?: Maybe<Scalars['String']>;
  documentationTitle?: Maybe<Scalars['String']>;
  documentationType?: Maybe<Scalars['String']>;
  productImage?: Maybe<Scalars['String']>;
};

export type ProductTypeInput = {
  id: Scalars['ID'];
  rating: Scalars['String'];
  noOfInjectors: Scalars['Int'];
  noOfPumps: Scalars['Int'];
  noOfECUs: Scalars['Int'];
  firmwarebundleFile?: Maybe<Scalars['String']>;
  firmwarebundleVersion?: Maybe<Scalars['String']>;
  firmwarebundleDate?: Maybe<Scalars['String']>;
  spn?: Maybe<Array<SpnInput>>;
  fmi?: Maybe<Array<FmiInput>>;
  documentationFile?: Maybe<Scalars['String']>;
  documentationTitle?: Maybe<Scalars['String']>;
  documentationType?: Maybe<Scalars['String']>;
  productImage?: Maybe<Scalars['String']>;
};

export type ProductTypesPage = {
  __typename?: 'ProductTypesPage';
  productTypes?: Maybe<Array<ProductType>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getTenant: Tenant;
  getTenants: TenantsPage;
  getProducts: ProductsListPage;
  getProductsHistory: ProductsPage;
  getProduct: Product;
  getProductHistory: Product;
  getProductTypes: ProductTypesPage;
  getProductType: ProductType;
  getFileUploadUrl: PresignedPost;
  getUser: User;
  getUsers: UsersPage;
  me: User;
};

export type QueryGetTenantsArgs = {
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetProductsArgs = {
  search?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetProductsHistoryArgs = {
  productSerial: Scalars['String'];
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetProductArgs = {
  id: Scalars['ID'];
};

export type QueryGetProductHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetProductTypesArgs = {
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetProductTypeArgs = {
  id: Scalars['ID'];
};

export type QueryGetFileUploadUrlArgs = {
  productType: Scalars['String'];
  type: FileType;
};

export type QueryGetUserArgs = {
  id: Scalars['ID'];
};

export type Spn = {
  __typename?: 'SPN';
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type SpnInput = {
  id: Scalars['Int'];
  description: Scalars['String'];
  unit: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
  login: Scalars['String'];
  password: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
};

export type TenantsPage = {
  __typename?: 'TenantsPage';
  tenants?: Maybe<Array<Tenant>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['AWSEmail'];
  groups?: Maybe<Array<Scalars['String']>>;
};

export type UserInput = {
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  groups?: Maybe<Array<Scalars['String']>>;
};

export type UsersPage = {
  __typename?: 'UsersPage';
  users?: Maybe<Array<User>>;
  nextToken?: Maybe<Scalars['String']>;
};
