import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, Box } from '@mui/material';

import { Product } from '../../types';

import FaultsView from './FaultsView';

const Notifications = () => {
  const record = useRecordContext<Product>();
  return (
    <>
      <Card>
        <CardContent>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
              <FaultsView
                faults={record?.activeFaultsMaster}
                freezeFrameFields={record?.freezeFrameFields}
                caption="Active DTC Messages - Master"
              />
            </Box>
          </Box>
          {record?.ecuSerialNumberSlave ? (
            <>
              <Box mt={3} />
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <FaultsView
                    faults={record?.activeFaultsSlave}
                    freezeFrameFields={record?.freezeFrameFields}
                    caption="Active DTC Messages - Slave"
                  />
                </Box>
              </Box>
            </>
          ) : null}
        </CardContent>
      </Card>
      <Box mt={3} />
      <Card>
        <CardContent>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
              <FaultsView
                faults={record?.previousActiveFaultsMaster}
                freezeFrameFields={record?.freezeFrameFields}
                caption="Previous DTC Messages - Master"
              />
            </Box>
          </Box>
          {record?.ecuSerialNumberSlave ? (
            <>
              <Box mt={3} />
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <FaultsView
                    faults={record?.previousActiveFaultsSlave}
                    freezeFrameFields={record?.freezeFrameFields}
                    caption="Previous DTC Messages - Slave"
                  />
                </Box>
              </Box>
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};

export default Notifications;
