import { defaultTheme } from 'react-admin';

export const caudwellTheme = {
  name: 'caudwell',
  logo: 'caudwell.png',
  palette: {
    primary: {
      main: '#002469',
    },
    secondary: {
      light: '#0051A2',
      main: '#283593',
      dark: '#0051a2',
      contrastText: '#fff',
    },
    background: {
      default: '#ebebec',
    },
    mode: 'light' as 'light',
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    ...defaultTheme.components,
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#fff',
          backgroundColor: '#0051a2',
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          'color': '#aaa',
          'borderLeft': '3px solid #0051a2',
          '&.RaMenuItemLink-active': {
            'borderLeft': '3px solid #fff',
            'color': '#fff',
            '& .RaMenuItemLink-icon': {
              color: '#fff',
            },
          },
          '& .RaMenuItemLink-icon': {
            color: '#aaa',
          },
        },
      },
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          '& .RaFileInput-dropZone': { background: '#f5f5f5' },
        },
      },
    },
    RaImageInput: {
      styleOverrides: {
        root: {
          '& .RaFileInput-dropZone': { background: '#f5f5f5' },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: '#0051a2',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          backgroundClip: 'padding-box',
        },
      },
    },
  },
};
