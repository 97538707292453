import * as React from 'react';
import { FC } from 'react';
import {
  Typography,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from '@mui/material';

import { Product } from '../../types';

import LoadHeatMap from './LoadHeatMap';
import { useRecordContext } from 'react-admin';

const processLoad = (
  load?: number[][] | null | undefined,
  loadX?: number[] | null | undefined,
  loadY?: number[] | null | undefined,
  unitY?: string,
) => {
  if (!load || !loadX || !loadY) {
    return {
      load: [],
      loadX: [],
      loadY: [],
    };
  }

  const historyLoadY = loadY && [
    ...loadY?.filter((x) => x <= 100 || unitY !== '%'),
  ];

  const historyLoadX = loadX && [...loadX];
  const historyLoad = load && load.map((a) => [...Array.from(a)].reverse());
  if (historyLoadY && historyLoad && historyLoadY?.length < load?.length) {
    historyLoad.pop();
  }
  historyLoad.reverse();

  return { load: historyLoad, loadX: historyLoadX, loadY: historyLoadY };
};

const Statistics = () => {
  const record = useRecordContext<Product>();
  return (
    <Card>
      <CardContent>
        {record?.historyEngineLoad ||
        record?.historyFuelPressure ||
        record?.historyOilPressure ? (
          <>
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <LoadHeatMap
                  caption="Engine Load (hours)"
                  unitX="rpm"
                  unitY="%"
                  {...processLoad(
                    record?.historyEngineLoad,
                    record?.historyEngineLoadX,
                    record?.historyEngineLoadY,
                    '%',
                  )}
                />
              </Box>
            </Box>

            <Box mt={3} />
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <LoadHeatMap
                  caption="Fuel Pressure (hours)"
                  unitX="MPa"
                  unitY=""
                  {...processLoad(
                    record?.historyFuelPressure,
                    record?.historyFuelPressureX,
                    [0],
                    '',
                  )}
                />
              </Box>
            </Box>

            <Box mt={3} />
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <LoadHeatMap
                  caption="Oil Pressure (hours)"
                  unitX="rpm"
                  unitY="kPa"
                  {...processLoad(
                    record?.historyOilPressure,
                    record?.historyOilPressureX,
                    record?.historyOilPressureY,
                    'kPa',
                  )}
                />
              </Box>
            </Box>
            <Box mt={3} />
          </>
        ) : null}
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              Engine Data
            </Typography>
            <TableContainer>
              <Table
                size="small"
                sx={{ maxWidth: '680px' }}
                aria-label="Engine data"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      width="33%"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Engine Runtime Master
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstEngineRunTimeMaster?.toFixed(2)} hours
                    </TableCell>
                    <TableCell
                      component="th"
                      width="33%"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Engine Speed Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstEngineSpeedMaxPeak?.toFixed(2)} rpm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Engine Runtime Slave
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstEngineRunTimeSlave?.toFixed(2)} hours
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                      }}
                    />
                    <TableCell
                      style={{
                        borderBottom: 'none',
                      }}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                      }}
                    />
                    <TableCell
                      style={{
                        borderBottom: 'none',
                      }}
                    />
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Air Temp Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstAirTempMaxPeak?.toFixed(2)} °C
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Air Pressure Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstAirPressureMaxPeak?.toFixed(2)} kPa
                    </TableCell>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Air Temp Min Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstAirTempMinPeak?.toFixed(2)} °C
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Fuel Pressure Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstFuelPressureMaxPeak?.toFixed(2)} MPa
                    </TableCell>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Coolant Temp Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstCoolantTempMaxPeak?.toFixed(2)} °C
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                      }}
                    />
                    <TableCell
                      style={{
                        borderBottom: 'none',
                      }}
                    />
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Coolant Temp Min Peak{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstCoolantTempMinPeak?.toFixed(2)} °C
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Oil Pressure Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstOilPressureMaxPeak?.toFixed(2)} kPa
                    </TableCell>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      {' '}
                      Hst Fuel Temp Max Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstFuelTempMaxPeak?.toFixed(2)} °C
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      Hst Oil Pressure Min Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstOilPressureMinPeak?.toFixed(2)} kPa
                    </TableCell>
                    <TableCell
                      component="th"
                      style={{
                        borderBottom: 'none',
                        paddingRight: '1px',
                      }}
                    >
                      {' '}
                      Hst Fuel Temp Min Peak
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingLeft: '1px',
                      }}
                    >
                      {record?.hstFuelTempMinPeak?.toFixed(2)} °C
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Statistics;
