import * as React from 'react';
import { ElementType, memo } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandRowButton = ({
  expanded,
  expandContentId,
  ...props
}: ExpandRowButtonProps) => {
  return (
    <IconButton
      aria-expanded={expanded}
      aria-controls={expandContentId}
      tabIndex={-1}
      aria-hidden="true"
      component="div"
      {...props}
      size="small"
      sx={{
        padding: (theme) => theme.spacing(1),
        transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
      }}
    >
      <ExpandMoreIcon fontSize="inherit" />
    </IconButton>
  );
};

export interface ExpandRowButtonProps extends IconButtonProps {
  component?: ElementType;
  expanded: boolean;
  expandContentId?: string;
}

export default memo(ExpandRowButton);
