import * as React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Layout, Login } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import buildDataProvider from './dataProvider';
import { getApolloContext } from '@apollo/client';

import productTypes from './productTypes';
import products from './products';
import users from './users';
import Configuration from './configuration/Configuration';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    // Always fallback on english
    return englishMessages;
  },
  'en',
  { allowMissing: true, onMissingKey: (key: any, _: any, __: any) => key },
);

function App() {
  const { client } = React.useContext(getApolloContext());
  const dataProvider = buildDataProvider(client!);
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      title=""
      loginPage={Login}
      layout={Layout}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      disableTelemetry
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
      </CustomRoutes>
      <Resource intent="registration" name="tenants" />
      <Resource intent="registration" name="productslist" />
      <Resource name="users" {...users} />
      <Resource
        name="productTypes"
        {...productTypes}
        options={{ label: 'Product types' }}
      />
      <Resource name="products" {...products} />
    </Admin>
  );
}

export default App;
