import * as React from 'react';
import { Typography, Card, CardContent, Box } from '@mui/material';
import { Labeled, TextField, FileField } from 'react-admin';

const ProductType = () => {
  return (
    <>
      <Card>
        <CardContent>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
              <Typography variant="h6">Product Manuals</Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Labeled label="Type" sx={{ padding: 1 }}>
                    <TextField source="documentationType" />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Labeled label="Title" sx={{ padding: 1 }}>
                    <TextField source="documentationTitle" />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Labeled label="Download link" sx={{ padding: 1 }}>
                    <FileField
                      source="documentationFile"
                      title="documentationFileTitle"
                    />
                  </Labeled>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
              <Typography variant="h6">Firmware</Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Labeled label="Firmware version" sx={{ padding: 1 }}>
                    <TextField source="firmwarebundleVersion" />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Labeled label="Filename" sx={{ padding: 1 }}>
                    <FileField
                      source="firmwarebundleFile"
                      title="firmwarebundleFileTitle"
                    />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Labeled label="Updated" sx={{ padding: 1 }}>
                    <TextField source="firmwarebundleDate" />
                  </Labeled>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ProductType;
